.contact-box {
  font-family: ProximaNovaLight;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.4vw;
  outline: white solid 1px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: auto;
  padding: 4pc 10% 4pc 10%;
  .inputSize {
    font-size: 1.2vw;
    width: 25vw;
    height: 2.5vw;
    @media screen and (min-width: 768px) and (max-width: 1000px) {
      width: 50vw;
      font-size: 3vw;
      height: 4.5vw;
    }
    @media screen and (max-width: 768px) {
      font-size: 4.25vw;
      width: 65vw;
      height: 7vw;
    }
  }
  > p {
      margin-bottom: 0;
      font-size: max(1.25rem, 0.8vw);
      color:rgba(255, 255, 255, 0.75);
  }
  input {
      outline: var(--ourorange) 2px solid;
      border: none;
      color: white;
      padding: 8px;
      border-radius: 8px;
      background-color: transparent;
  }
  br {
      margin-bottom: 3rem;
  }
  &.success {
    p {
      color: white;
      font-size: 2vw;
      font-family: ProximaNovaBold;
    }
    img {
      margin: auto;
      margin-bottom: 1vw;
      width: 5vw;
      height: 5vw;
    }
    @media screen and (min-width: 768px) and (max-width: 1000px) {
      p {
        font-size: 4vw;
      }
      img {
        width: 15vw;
        height: 15vw;
      }
    }
    @media screen and (max-width: 768px) {
      p {
        font-size: 6vw;
      }
      img {
        width: 15vw;
        height: 15vw;
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1000px) {
    > p {
      margin-bottom: 0;
      font-size: 3vw;
      color:rgba(255, 255, 255, 0.75);
    }
  }
  @media screen and (max-width: 768px) {
    border-radius: 1pc;
    > p {
      margin-bottom: 0;
      font-size: 3vw;
      color:rgba(255, 255, 255, 0.75);
    }
    input {
        outline: var(--ourorange) 2px solid;
        border: none;
        color: white;
        padding: 8px;
        border-radius: 8px;
        background-color: transparent;
    }
    br {
        margin-bottom: 3rem;
    } 
  }
}

.contact-top-text {
  text-align: center;
  font-family: ProximaNovaLight;
  color: white;
  margin: auto;
  margin-top: 4vw;
  margin-bottom: 4vw;
  width: 40vw;
  font-size: var(--normal-p-1-5);
  line-height: var(--normal-p-1-5);
  @media screen and (max-width: 1024px) {
    width: 85vw;
    text-align: left;
    font-size: var(--smaller-p);
    line-height: var(--smaller-p);
    margin-top: 10vh;
    margin-bottom: 10vh;
  }
  @media screen and (max-width: 768px) {
    font-size: var(--normal-p);
    line-height: var(--normal-p);
  }
}

.captchaNotice {
  font-family: ProximaNovaLight;
  margin: auto;
  margin-bottom: 1.25rem;
  width: 80%;
  color: white;
  font-size: 1.5vw;
  line-height: 1.5vw;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
  @media screen and (max-width: 1000px) {
    font-size: 2.5vw;
    line-height: 2.5vw;
  }
}

.contact-nav-buttons {
    margin-top: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
    @media screen and (max-width: 1000px) {
      width: 80%;
      text-align: center;
      button {
        font-size: 3vw;
        &.btn.disabled:hover {
          color: white;
          background-color: transparent;
          border-color: white;
        }
      }
    }
    @media screen and (max-width: 768px) {
      button {
        font-size: 1.5rem;
      }
    }
}


.contact-dropdown {
    scrollbar-gutter: stable;
    position: relative;
    top: 0;
    left: 0;
    min-width: 14rem;
    background-color: rgba(255, 255, 255, 1.0);
    border-radius: 0.3rem;
    display: flex;
    flex-direction: row;
    overflow-y: auto;
    padding: 0;
    p {
      text-align: center;
      width: 100%;
      color: var(--darkblue) !important;
      border-bottom: 1px solid var(--darkblue);
      margin-bottom: 0;
      font-family: ProximaNovaLight !important;
      font-size: max(1.5rem, 1.1vw) !important;
      transition: all ease-in-out 200ms;
      cursor: pointer;
    }
    p:last-child {
      border-bottom: none;
    }
    p:hover {
      background-color: rgba($color: #fff, $alpha: 0.15);
    }
    opacity: 0;
    max-height: 0;
    display: inherit;
    transition: all ease-in-out 200ms;
  }
  .contact-dropdown.hover {
    padding: 0.5rem;
    margin-top: 8px;
    max-height: 25vh;
    opacity: 1;
  }

  .contact-dropdown-box {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    color: white;
    background-color: transparent;
    border: 2px solid var(--ourorange);
    border-radius: var(--bs-border-radius);
    padding: 4px;
    margin-left: 8px;
    width: 25vw;
    font-size: 1.2vw;
    height: 2.5vw;
    cursor: pointer;
    transition: background-color ease-in-out 100ms;
    background-image: url("../img/icon/input-dropdown-icon.svg");
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 2pc;
  }
  .contact-dropdown-box:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }