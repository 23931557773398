.headline-howto {
  @extend .headline;
  color: #ff6641ff;
}


.howto-bullet {
  text-align: center;
  position: absolute;
  width: 14%;
  transform: translateY(-100%);
  opacity: 0;
  transition: 500ms ease-out;
  transition-property: opacity transform;
}

.howto-fade-in .howto-bullet {
  opacity: 1;
  transform: translateY(0%);
}
.howto-instant .howto-bullet {
  opacity: 1;
  transform: translateY(0%);
  transition-duration: 0ms;
  transition-delay: 0ms;
}

.howto-bullet:nth-child(1) {
  bottom: 35%;
  left: 1.8%;
  transition-delay: 0s;
  z-index: 0;
}
.howto-bullet:nth-child(2) {
  bottom: 45%;
  left: 17.8%;
  transition-delay: 0.5s;
  z-index: 0;
}
.howto-bullet:nth-child(3) {
  bottom: 37%;
  left: 39%;
  transition-delay: 1s;
  z-index: 0;
}
.howto-bullet:nth-child(4) {
  bottom: 42%;
  left: 65%;
  transition-delay: 1.5s;
  z-index: 0;
}
.howto-bullet:nth-child(5) {
  bottom: 46.5%;
  left: 82.75%;
  transition-delay: 2s;
  z-index: 0;
}

.howto-bullet .title {
  font-family: "DeLittleChromaticBold";
  color: white;
  font-size: 2vw;
  line-height: 2vw;
  margin-bottom: 2vw;
}

.howto-bullet .description {
  font-family: "ProximaNovaLight";
  color: #EEEFF0;
  text-align: left;
  font-size: 1.3vw;
  line-height: 1.4vw;
}

.howto-bullet .GPS {
  width: 4rem;
  height: 4rem;
  padding-top: 1.9rem;
  padding-right: 2.45rem;
  margin-left: calc(50% - 2rem);
  background-image: url('../../assets/img/icon/GPS_filled_selected.svg');
  background-repeat: no-repeat;
  font-family: "DeLittleChromaticBold";
  color: black;
  font-size: 2.4rem;
  line-height: 0px;
  transition: 200ms ease-in-out;
  cursor: pointer;
  @media screen and (min-width: 3200px) {
    width: 8rem;
    height: 8rem;
  }
}

.howto-bullet .GPS span {
  position: absolute;
  @media screen and (min-width: 3200px) {
    position: relative;
    font-size: 2em;
    left: 0;
    top: 30%;
  }
}

.howto-bullet .GPS div {
  opacity: 1;
  position: absolute;
  bottom: 0rem;
  width: 4rem;
  height: 4rem;
  transition: 150ms ease-in-out;
  background-image: url('../../assets/img/icon/GPS_filled_unselected.svg');
  background-repeat: no-repeat;
  @media screen and (min-width: 3200px) {
    width: 8rem;
    height: 8rem;
  }
}

.howtotooltip {
  z-index: 2;
  opacity: 0;
  transform: scale(0);
  position: fixed;
  transition: opacity;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
  width: 38vw;
  height: 24vw;
  padding: 3vw 2vw 3vw 5vw;
  background-image: url("../img/icon/howToLeft.png");
  background-size: contain;
  background-repeat: no-repeat;
  /*@media screen and (min-width: 2000px) {
    width: 41vw;
    height: 30vw;
  }*/
  color: white;
  font-family: "ProximaNovaLight";
  font-size: 1.3vw;
  line-height: 1.4vw;
  display: flex;
  flex-direction: column;
  p {
    flex-shrink: 0;
  }
  img {
    width: 100%;
    height: 0;
    flex-grow: 1;
    object-fit: scale-down;
  }
  .title {
    text-align: center;
    color: white;
    font-family: "DeLittleChromaticBold";
    font-size: 1.5vw;
    line-height: 1.5vw;
    @media screen and (min-width: 3200px) {
      margin-top: 2%;
    }
  }
}

.howto-bullet .GPS:hover ~ .howtotooltip {
  transform: scale(1);
  opacity: 1;
}

.howtotooltip:hover {
  transform: scale(1);
  opacity: 1;
}

.howtoreverseTooltip {
  @extend .howtotooltip;
  padding: 3vw 5vw 3vw 2vw;
  background-image: url("../img/icon/howToRight.png");
}

.howto-bullet .GPS:hover ~ .howtoreverseTooltip {
  transform: scale(1);
  opacity: 1;
}

.howtoreverseTooltip:hover {
  transform: scale(1);
  opacity: 1;
}

.howto-bullet .GPS:hover div:nth-child(1) {
  opacity: 0;
}

.tmpShow {
  transform: scale(1) !important;
  opacity: 1 !important;
}


.howto-bullet-mobile {
  text-align: center;
  width: 90%;
  .title {
    font-family: "DeLittleChromaticBold";
    color: white;
    font-size: 6vw;
    line-height: 6vw;
    margin-top: 2rem;
    margin-bottom: 2rem;
    flex: 1;
  }
  .description {
    font-family: "ProximaNovaLight";
    color: #EEEFF0;
    text-align: center;
    font-size: 5vw;
    line-height: 5vw;
  }
  .GPS {
    width: 4rem;
    height: 4rem;
    padding-top: 1.9rem;
    padding-right: 2.45rem;
    margin: auto;
    background-image: url('../../assets/img/icon/GPS_filled_unselected.svg');
    background-repeat: no-repeat;
    font-family: "DeLittleChromaticBold";
    color: black;
    font-size: 2.4rem;
    line-height: 0px;
    cursor: pointer;
    span {
      position: absolute;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5vw;
    img {
      flex: 1;
      max-width: 75%;
    }
  }
}