.faq-headline {
    font-family: "ProximaNovaExtraBold";
    font-size: 4rem;
    transition: margin-left ease-in-out 100ms;
    color: var(--darkblue);
    text-align: center;
    margin-bottom: 4rem;
    text-transform: uppercase;
  }

#faq-questions {
  overflow-y: auto;
  overflow-x: hidden;
  width: 70%;
  margin-left: auto;
  margin-right: auto;

  --sb-track-color: #1e272b;
  --sb-thumb-color: var(--ourorange);
  --sb-size: 8px;

  scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
  scrollbar-gutter: stable;

  @media screen and (max-width: 850px) {
    width: 80%;
  }

  @media screen and (max-width: 650px) {
    width: 90%;
  }

  @media screen and (min-width: 3000px) {
    width: 25%;    
  }
}

.faq-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: fit-content;
}

#faq-questions ::-webkit-scrollbar {
  width: var(--sb-size);
}

#faq-questions ::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 15px;
}

#faq-questions ::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 15px;
}

#faq-questions .box {
  text-align: center;
  border: none;
  border-radius: 0.6rem;
  width: 100%;
  height: max-content;
  font-family: "ProximaNovaBold";
  color: white;
  font-size: 1.75rem;
  line-height: 2.25rem;
  padding: 0.5rem;
  margin-bottom: 0.6rem;
  background-color: rgba(#2f385f, $alpha: 0.9);
  transition: opacity 200ms ease-in-out;
  cursor: pointer;
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    font-size: 2rem;
    line-height: 2rem;
    margin-bottom: 0.6rem;
  }
}

#faq-questions.unselected .box {
  opacity: 0.5;
}

#faq-questions.unselected .box:hover {
  opacity: 0.8;
}

#faq-questions.unselected .box.selected {
  opacity: 1;
}

#faq-questions .box h2 button {
  background-color: transparent;
  font-family: "ProximaNovaBold";
  color: white;
  font-size: 1.5rem;
  line-height: 2.25rem;
  text-align: center;
  display: block;
  padding: 0;
  outline: none;
  border: none;
  box-shadow: none !important;

  @media screen and (max-width: 500px) {
    font-size: 1.3rem;
    line-height: 1.5rem;
  }
}

#faq-answer {
  display: flex;
  text-align: left;
  border: 1px solid white;
  border-radius: 0.6rem;
  font-family: "ProximaNovaLight";
  color: white;
  font-size: 1.1rem;
  line-height: 1.3rem;
  padding: 2.5rem;
  background-color: rgba($color: #000000, $alpha: 0.75);
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow-y: auto;

  --sb-track-color: #2c4550;
  --sb-thumb-color: #bbf7d9;
  --sb-size: 6px;

  scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);

  .bigger {
    font-size: 3rem;
    line-height: 3rem;
  }
  @media screen and (max-width: 1200px) {
    font-size: 1.2rem;
    line-height: 1.6rem;
    .bigger {
      font-size: 2rem;
      line-height: 2rem;
    }
  }
  @media screen and (min-width: 2400px) {
    font-size: 3rem;
    line-height: 3.2rem;
    .bigger {
      font-size: 4.5rem;
      line-height: 4.5rem;
    }
  }
}

#faq-answer div:nth-child(1) {
  max-width: 100%;
  max-height: 100%;
}

#faq-answer::-webkit-scrollbar {
  width: var(--sb-size);
}

#faq-answer::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 15px;
}

#faq-answer::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 15px;
  border: 1px solid #232e33;
}

.toggleIcon {
  float: right;
  margin-right: 10px;
}


.dropdown-question-holder {
  overflow-y: auto;
  width: 80%;
  height: 100%;
  margin: auto;
  padding-bottom: 3pc;
}

.dropdown-question {
  color: white;
  width: 100%;
  > div {
    display: flex;
    flex-direction: row;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 1pc;
    border: white solid 1px;
    padding: 1pc;
    align-items: center;
  }
  h1 {
    font-family: ProximaNovaBold;
    text-align: center;
    font-size: 5rem;
    margin-bottom: 0;
    flex-grow: 1;
  }
  p {
    display: none;
    font-family: ProximaNovaLight;
    margin: auto;
    text-align: left;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 1pc;
    border: white solid 1px;
    padding: 3pc;
    margin-top: 1pc;
    font-size: var(--normal-p);
    line-height: 6rem;
    animation: 200ms 1 forwards ease-in-out popInQuestion;
    transform-origin: top;
  }
  .arrow {
    --size: 5rem;
    min-width: var(--size);
    max-width: var(--size);
    min-height: var(--size);
    max-height: var(--size);
    margin-right: 0.5pc;
    flex-shrink: 0;
    transition: all ease 150ms;
    filter: brightness(0) saturate(100%) invert(100%) sepia(4%) saturate(1146%)
      hue-rotate(47deg) brightness(116%) contrast(100%);
  }
  margin-bottom: 3rem;
  &.expand {
    .arrow {
      transform: rotate(180deg);
    }
    p {
      display: block;
    }
  }
}

.answer {
  font-size: 1.4rem;
  text-align: justify;
  line-height: normal;
  font-family: "ProximaNovaLight";
  padding: 40px;
  padding-top: 30px;

  @media screen and (max-width: 950px) {
    padding: 20px;
  }

  @media screen and (max-width: 700px) {
    font-size: 1.2rem;
  }
  
  @media screen and (max-width: 500px) {
    font-size: 1.1rem;
    padding: 10px;
    padding-top: 20px;
  }
}

@keyframes popInQuestion {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}
