.curriculum-content {
    width: 100%;
    min-height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 6rem;
}

.curriculum-headline {
    font-family: "ProximaNovaExtraBold";
    font-size: 4rem;
    transition: margin-left ease-in-out 100ms;
    color: white;
    text-align: center;
    margin-bottom: 4rem;
    text-transform: uppercase;

    @media screen and (max-width: 1480px) {
        font-size: 3rem;
    }

    @media screen and (max-width: 768px) {
        font-size: 2rem;
    }
}

.curriculum-subtext {
    color: white;
    font-family: ProximaNovaLight;
    width: 50%;
    font-size: 1.5rem;
    line-height: 2rem;
    @media screen and (min-width: 2000px) {
        font-size: 2.5rem;
        line-height: 2.5rem;
        width: 40%;
    }
    @media screen and (max-width: 768px) {
        width: 75%;
    }
}

.curriculum-poster {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1.5rem;
    img {
        width: 18rem;
        height: auto;
        object-fit: contain;
        -webkit-filter: drop-shadow(6px 8px 10px rgba(0, 0, 0, 0.5));
        filter: drop-shadow(6px 8px 10px rgba(0, 0, 0, 0.5));
        caret-color: transparent;
    }
    img:first-child {
        z-index: 5;
        transform: rotate(-6deg);
    }
    img:last-child {
        z-index: 6;
        transform: rotate(4deg);
        margin-top: 4rem;
    }

    @media screen and (min-width: 2000px) {
        margin-top: 2rem;
        img {
            width: 25rem;
        }
    }

    @media screen and (max-width: 768px) {
        img {
            width: 10rem;
        }
    }
}