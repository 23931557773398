.about-content {
    position: relative;
    padding-top: 1rem;
    padding-bottom: 2rem;
    height: 50%;
}

.about-note {
  width: 33vw;
  font-family: "ProximaNovaLight";
  color: #ffffff;
  font-size: 1.75rem;
  line-height: 110%;
  margin-bottom: 1rem;
  cursor: default;

  @media screen and (max-width: 1400px) {
    font-size: 1.5rem;
  }
  @media screen and (max-width: 1200px) {
    width: 70vw;
  }

  @media screen and (max-width: 1024px) {
    line-height: normal;
    font-size: 1.3rem;
  }

  @media screen and (max-width: 800px) {
    width: 85vw;
  }
}

.about-img {
  width: 40%;
  border-radius: 2pc;

  @media screen and (max-width: 1400px) {
    width: 35%;
  }

  @media screen and (max-width: 1200px) {
    width: 40vw;
  }

  @media screen and (max-width: 1100px) {
    width: 50vw;
  }

  @media screen and (max-width: 900px) {
    width: 60vw;
  }

  @media screen and (max-width: 600px) {
    width: 70vw;
  }

  @media screen and (max-width: 500px) {
    width: 85vw;
  }
}

.thirdpartyrating3 {
  right: var(--sideTextSpacing);
  top: var(--textBottomMargin);
}

.about-container-mobile {
  margin: auto;
  width: 100%;
  scroll-behavior: smooth;
  text-align: center;
  padding: 0 !important;
  &.scroller {
    padding-bottom: 4dvh !important;
  }
  > img {
    width: 67dvw;
    border-radius: 2pc;
    max-width: 100%;
  }
  p {
    width: 75%;
    margin: auto;
    margin-top: 3pc;
    font-family: ProximaNovaLight;
    font-size: var(--normal-p);
    line-height: var(--normal-p);
    color: white;
    text-align: left;
  }
  h2 {
    color: var(--ourorange);
    font-family: ProximaNovaBold;
    font-size: 4.75rem;
    text-wrap: nowrap;
    &.rating {
      display: flex;
      font-size: 10rem;
      align-items: center;
      justify-content: center;
      img {
        margin-top: 0;
        margin-right: 4px;
        &:nth-child(1) {
          margin-left: 6px;
        }
      }
    }
  }
  h1 {
    color: white;
    font-family: DeLittleChromaticBold;
    font-size: 5rem;
    margin-bottom: 0;
  }
  a {
    color: white;
    font-family: ProximaNovaLight;
    font-size: 4rem;
    text-decoration: none;
  }
  space {
    display: block;
    margin-top: 4pc;
    margin-bottom: 4pc;
  }
  .star {
    display: inline;
    --size: 7.5rem;
    width: var(--size);
    height: var(--size);
    max-width: var(--size);
    max-height: var(--size);
  }
}

.about-content-holder {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  margin: auto;
  margin-top: 5rem;
  @media screen and (max-width: 1200px) {
    margin-top: 1rem;
    height: 60%;
    flex-direction: column;
  }
}

.ratings-holder {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10vw;

  .thirdPartyRatingDefaults {
    color: #ffffff;
    --sideTextSpacing: 6%;
    --textBottomMargin: 75%;
    text-align: center;
    width: 100%;
  }

  .thirdPartyRatingDefaults p:nth-child(1) {
    color: #ff6641;
    font-family: "ProximaNovaBold";
    font-size: 2rem;
    line-height: 2.4rem;
  }
  .thirdPartyRatingDefaults p:nth-child(2) {
    font-family: "DeLittleChromaticBold";
    font-size: 1.7rem;
    line-height: 1.6rem;
    text-transform: uppercase;
  }
  .thirdPartyRatingDefaults p:nth-child(3) {
    font-family: "ProximaNovaLight";
    font-size: 1.2rem;
    line-height: 1rem;
  }
  .thirdPartyRatingDefaults a:nth-child(3) {
    font-family: "ProximaNovaLight";
    font-size: 1.2rem;
    line-height: 1rem;
    color: white;
    transition: color 0.1s ease-in-out;
  }
  .thirdPartyRatingDefaults a:nth-child(3):hover {
    color: #ff6641;
  }

  .thirdpartyrating1 {
  }

  .thirdpartyrating2 {
  }

  .thirdpartyrating2 img {
    width: 2rem;
    height: 2rem;
    transform: translateY(-0.25em);
    margin-right: 4px;
  }
  .thirdpartyrating2 p:nth-child(1) img:nth-child(1) {
    margin-left: 0.5pc;
  }

  @media screen and (max-width: 1400px) {
    .thirdPartyRatingDefaults p:nth-child(1) {
      font-size: 1.5rem;
      line-height: 2.4rem;
    }
    .thirdPartyRatingDefaults p:nth-child(2) {
      font-size: 1.4rem;
      line-height: normal;
    }
    .thirdPartyRatingDefaults p:nth-child(3) {
      font-size: 1rem;
      line-height: 1rem;
    }
    .thirdPartyRatingDefaults a:nth-child(3) {
      font-size: 1rem;
      line-height: 1rem;
    }

    .thirdpartyrating2 img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }


  @media screen and (max-width: 1000px) {
    .thirdPartyRatingDefaults p:nth-child(1) {
      font-size: 1.2rem;
      line-height: normal
    }
    .thirdPartyRatingDefaults p:nth-child(2) {
      font-size: 1.2rem;
      line-height: normal;
    }
    .thirdPartyRatingDefaults p:nth-child(3) {
      font-size: 1rem;
      line-height: 1rem;
    }
    .thirdPartyRatingDefaults a:nth-child(3) {
      font-size: 1rem;
      line-height: 1rem;
    }

    .thirdpartyrating2 img {
      width: 1rem;
      height: 1rem;
    }
  }

  @media screen and (max-width: 1250px) {
    margin-top: 5vw;
  }

  @media screen and (max-width: 550px) {
    flex-direction: column;

    .thirdPartyRatingDefaults p:nth-child(1) {
        font-size: 0.8rem;
        line-height: normal
      }
      .thirdPartyRatingDefaults p:nth-child(2) {
        font-size: 0.8rem;
        line-height: normal;
      }
      .thirdPartyRatingDefaults p:nth-child(3) {
        font-size: 0.8rem;
        line-height: normal;
      }
      .thirdPartyRatingDefaults a:nth-child(3) {
        font-size: 0.8rem;
        line-height: normal;
      }
  
      .thirdpartyrating2 img {
        width: 0.7rem;
        height:0.7rem;
      }
  }


}

.mobile-ratings-holder {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10vw;

  .thirdPartyRatingDefaults {
    color: #ffffff;
    --sideTextSpacing: 6%;
    --textBottomMargin: 75%;
    text-align: center;
    width: 100%;

    p {
      margin-top: 1rem;
      text-align: center;
    }
  }

  .thirdPartyRatingDefaults p:nth-child(1) {
    color: #ff6641;
    font-family: "ProximaNovaBold";
    font-size: 4vw;
    line-height: normal;
  }
  .thirdPartyRatingDefaults p:nth-child(2) {
    font-family: "DeLittleChromaticBold";
    font-size: 4vw;
    line-height: 1.6rem;
    text-transform: uppercase;
  }
  .thirdPartyRatingDefaults p:nth-child(3) {
    font-family: "ProximaNovaLight";
    font-size: 4vw;
    line-height: 1rem;
  }
  .thirdPartyRatingDefaults a:nth-child(3) {
    font-family: "ProximaNovaLight";
    font-size: 4.5vw;
    line-height: 1rem;
    color: white;
    transition: color 0.1s ease-in-out;
  }
  .thirdPartyRatingDefaults a:nth-child(3):hover {
    color: #ff6641;
  }

  .thirdpartyrating1 {
  }

  .thirdpartyrating2 {
  }

  .thirdpartyrating2 img {
    width: 4vw;
    height: 4vw;
    transform: translateY(-0.25em);
    margin-right: 4px;
  }
  .thirdpartyrating2 p:nth-child(1) img:nth-child(1) {
    margin-left: 0.5pc;
  }




}

.about-container-mobile-stretch {
  display: flex;
  flex-direction: column;
  flex: 1;
}
