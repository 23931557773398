#planner-title {
  transition: all 0.5s ease-in-out;
  margin-top: 16vh;
  height: 5rem;
  font-size: 3rem;

  @media screen and (max-width: 1024px) {
    height: 3rem;
    font-size: 2.5rem;
  }

  @media screen and (max-width: 777px) {
    height: 7rem;
    font-size: 2.5rem;
  }

  @media screen and (max-width: 600px) {
    height: 6rem;
    font-size: 2.2rem;
  }

  @media screen and (max-width: 450px) {
    height: 5.5rem;
    font-size: 1.6rem;
  }

  @media screen and (max-width: 302px) {
    height: 7rem;
    font-size: 1.6rem;
  }
}

.lowered-title {
  margin-top: 26vh !important;
}

.planner-title-hidden {
  opacity: 0 !important;
  padding: 0 !important;
  height: 0 !important;
}

.planner-content-space {
  position: relative;
  overflow: hidden;
}

.simple-tour-planner-container {
  border-color: var(--ourorange);
  border-style: solid;
  border-width: 2px;
  border-radius: 3rem;
  overflow: auto;
  width: 95%;
  min-height: fit-content;
  position: relative;
  padding: 0;
  transition: height 0.5s ease-in-out;
  height: calc(90vh - 6rem);
  margin-bottom: 50px;

  @media screen and (max-width: 800px) {
    border-radius: 2rem;
  }

  @media screen and (max-width: 800px) {
    border-radius: 2rem;
  }

  .adress-col {
    overflow-y: unset;
  }
}
.planner-background {
  position: absolute;
  min-width: 100%;
  height: 100%;
  /*   object-fit: cover;
  object-position: top; */
  overflow: hidden;
  left: 50%;
  transform: translateX(-50%);
}

.planner-background.loading {
  --blur-amount: 6px;
  filter: blur(var(--blur-amount));
  -webkit-filter: blur(var(--blur-amount));
  -moz-filter: blur(var(--blur-amount));
  overflow: hidden;
  transform: scale(1.05);
  @media only screen and (max-width: 1200px), (min-width: 1921px) {
    transform: scale(1.05) translateX(-50%);
  }
}
.planner-background.bg {
  display: none;
}

.full-planner {
  height: 80%;
}

.refresh-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 6rem;
  height: 5rem;
  border: none;
  background-color: var(--ourorange);
  border-bottom-right-radius: 1rem;
  font-size: 3rem;
  transition: color 300ms ease;
  color: #ffffffb0;
  z-index: 2;

  @media screen and (max-width: 800px) {
    font-size: 2rem;
    width: 4rem;
    height: 3rem;
  }
}

.refresh-button i {
  display: inline-block;
  transition: transform 0s cubic-bezier(0.4, 0, 0.2, 1);
}

.refresh-button:hover {
  color: #ffffffff;
}

@keyframes counterRotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.refresh-button:hover i {
  animation: counterRotate 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
}

.mode-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 12rem;
  height: 4rem;
  border-left: 2px solid var(--ourorange);
  border-bottom: 2px solid var(--ourorange);
  background-color: transparent;
  border-bottom-left-radius: 1rem;
  font-size: 1rem;
  color: var(--darkblue);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  z-index: 3;
  font-family: ProximaNovaBold;

  /*Vorrübergehend ausblenden*/
  display: none;

  @media screen and (max-width: 800px) {
    font-size: 0.8rem;
    width: 9rem;
    height: 3rem;
  }
}

/*Export Switch*/
//TODO: Auslagern

.simple-tour-planner-how {
  .form-switch {
    display: flex;
    gap: 25px;
    flex-direction: row-reverse;
    width: 247px;
    justify-content: space-around;
    padding: 0;
    font-family: ProximaNovaBold;
    align-items: flex-end;
  }

  .form-switch .form-check-input {
    --bs-form-switch-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e) !important;

    background-color: var(--darkblue);
    border: none;
    width: 48px;
    height: 24px;
    cursor: pointer;
  }

  .form-switch .form-check-input:checked {
    background-color: var(--ourorange);
    border: none;
  }

  .form-switch .form-check-input:focus {
    border: none;
    box-shadow: none;
  }

  /* Stelle sicher, dass der Ball in ALLEN Zuständen weiß ist */
  .form-check-input:before,
  .form-check-input:not(:checked):before,
  .form-check-input:disabled:before,
  .form-check-input:checked:disabled:before {
    background-color: white !important;
  }

  .form-switch .form-check-label {
    cursor: pointer;
  }

  .form-switch .form-check-input:disabled,
  .form-switch .form-check-input:disabled + .form-check-label {
    cursor: not-allowed;
  }
}

.custom-progress {
  caret-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: transparent !important;
  border: 1px solid var(--ourorange);
  border-radius: 8px;
  padding-left: 4px;
  padding-right: 4px;

  /* Each individual Step */
  & > div {
    transform: skewX(-25deg); // Slanted view
    height: 1rem;
    width: 100%;
    padding: 0.2rem;
    --sidepad: 0.3rem;
    padding-left: var(--sidepad);
    padding-right: var(--sidepad);
    border-right: 1px solid var(--ourorange);

    &.clickable {
      cursor: pointer;
    }

    /* Inside the steps for the padded background color fill */
    > div {
      width: 100%;
      height: 100%;
      &.activated {
        background-color: var(--ourorange);
      }
    }
  }

  /* Last Element of the progressbar doesn't need a border to the right for the next element */
  & > div:last-child {
    border-right: none;
  }
}

/*Dropdown*/

.custom-select-container {
  position: relative;
  width: 100%;
  min-width: 16rem;
}

.custom-select {
  appearance: none;
  background-color: transparent !important;
  border: 2px solid var(--ourorange) !important;
  border-radius: 8px !important;
  padding-right: 3rem !important;
  color: inherit;
  min-height: 3rem;
  --font: ProximaNovaBold;
  font-family: var(--font);
  font-size: 1.5rem;
  cursor: pointer;
  text-align: center;
  option {
    font-family: var(--font), "arial";
  }

  @media screen and (max-width: 600px) {
    font-size: 1.2rem;
    padding: 0;
    margin: 0;
    min-height: 2.6rem;
  }
}

/* Styling für das native Dropdown-Icon */
.custom-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ff6b00' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m2 5 6 6 6-6'/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat !important;
  background-position: right 0.75rem center !important;
  background-size: 1rem !important; /* Größeres Icon */
}

/* Vertikale Trennlinie */
.custom-select-container::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 2.8rem;
  width: 2px;
  height: 100%;
  background-color: var(--ourorange);
  transform: translateY(-50%);
}

.custom-select:focus {
  box-shadow: none !important;
  border-color: var(--ourorange) !important;
}

.custom-number-input {
  background-color: transparent;
  border: 2px solid var(--ourorange);
  border-radius: 8px;
  color: #000;
  min-height: 3rem;
  text-align: center;
  font-size: 1.5rem;
  font-family: ProximaNovaBold;
  padding-right: 3rem !important;

  @media screen and (max-width: 600px) {
    font-size: 1.2rem;
    padding: 0;
    margin: 0;
    min-height: 2.6rem;
  }
}

.custom-number-input:focus {
  outline: none;
  box-shadow: 0 0 4px var(--ourorange);
}

/* Fuck you Mozilla Firefox 
(This displays the fake spinner buttons) */
@supports (-moz-appearance: textfield) {
  .custom-number-input {
    -moz-appearance: textfield;
    padding-right: 0.2rem !important; // Subtract 2.8rem of 3rem of the original padding minus the ::after padding, equals the perfect padding in this layout
  }
  .custom-select-container:has(.custom-number-input) {
    &::after {
      display: none;
    }
    input {
      border: none;
    }
    border: var(--ourorange) 2px solid;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    .spinner-buttons {
      display: flex !important;
      flex-direction: column;
      button {
        background: transparent
          url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ff6641' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m2 5 6 6 6-6'/%3e%3c/svg%3e")
          no-repeat center center;
          background-size: 40%;
        flex: 1;
        min-width: 2.8rem;
        border: none;
        --border: var(--ourorange) solid 2px;
        outline-offset: -3px;
        &:hover {
          outline: #0000004d dashed 1px;
        }
      }
      button:first-child {
        rotate: 180deg;
        border-right: var(--border);
        border-bottom-left-radius: 8px;
      }
      button:last-child {
        border-left: var(--border);
        border-bottom-right-radius: 8px;
      }
    }
  }
}

/* Spin Buttons modified */
.custom-number-input::-webkit-outer-spin-button,
.custom-number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  background: transparent
    url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFGSURBVHgB7dbRTcMwEAbg+5uFGCGwAGxAeGglniomIJ2A9gWk8NJOULoAMhvACEzAAICOq4UikKPc2RYSqvw/WbWbfIoT3xH9g4AywrP6nBgNgVfo3AMlJhnhAYR1/0PFDe7chhKShAgAmZAJRSYAMD/140+s+XI//4eI8AlggXtXy8wiB2LejkFA99j+mJcxrvvpiK0xITRALkRFyIXPZNlWA+RA9HeCMbcC/IrOtb/ekQ9ckBIdUfFGvoAXufCVBgggTK9ykC2ppKSk5BCi145pfSTn6lxOv11MC+drDuiUKlrh1j2PrTX0E7jxfaQUMWuf8F11t/5/79Rq63UEeNePDQ1LUPYxGX0KJoRswdLaOVn7juAeZIzWJ6QCohBjkBxANGIQArk5c5MKSEIMQjIAyYhhSBogO3sIT4/feHbSUsmh5AtAq7cJJq1EvgAAAABJRU5ErkJggg==")
    no-repeat center center;
  width: 2.8rem;
  opacity: 1; /* shows Spin Buttons per default (Chrome >= 39) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}
.custom-number-input::-webkit-inner-spin-button:hover,
.custom-number-input::-webkit-inner-spin-button:active {
  box-shadow: 0 0 2px #0cf;
  opacity: 0.8;
}

.custom-number-input:focus {
  background-color: transparent;
  outline: none;
  border-color: var(--ourorange);
  box-shadow: none !important;
}

/* Override browser form filling */
input:-webkit-autofill {
  background: black;
  color: red;
}

/*Custom Radio button*/

.form-check {
  display: flex;
  align-items: center;
  gap: 8px;
}

.form-check-input[type="radio"] {
  background-color: transparent;
  border: 2px solid #ff6641;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.form-check-input:checked[type="radio"] {
  background-color: transparent;
  border-color: #ff6641;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ff6641'/%3e%3c/svg%3e");
}

.form-check-input[type="radio"]:focus {
  box-shadow: none;
  outline: none;
}

.form-check-input[type="radio"] + .form-check-label {
  font-family: ProximaNovaLight;
  font-size: 1.5rem;
  margin-left: 1rem;
  margin-right: 3rem;
  margin-top: 0.4rem;
  cursor: pointer;
}

.form-check-input[type="radio"]:checked + .form-check-label {
  font-family: ProximaNovaBold;
}

.player-count-error {
  color: red;
  font-family: ProximaNovaLight;
  font-size: 1rem;
  overflow: visible;
  height: 0;
}

/*Simple Tour Planner*/

.button-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 2rem;
}

.planner-button {
  font-size: 1.8rem;
  text-transform: uppercase;
  border-radius: 1pc;
  font-family: ProximaNovaBold;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  @media screen and (max-width: 400px) {
    font-size: 1.3rem;
  }
}

.plan_cont_button {
  color: black;
  background-color: #ff6641;
  border-color: #ff6641;
  text-transform: uppercase;
  border-radius: 1pc;
}
.simple_plan_back_button {
  color: black;
  background-color: transparent;
  border-color: black;
  text-transform: uppercase;
  border-radius: 1pc;
  margin-right: 1pc;
  border: black 2px solid;
  &.btn:hover {
    background-color: white;
    color: var(--ourorange);
    border-color: transparent;
  }
}
.btn:disabled {
  background-color: transparent;
  border-color: initial;
  color: #000000b3;
  border-color: #000000b3;
}

.planner-content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.progress-bar-container {
  position: relative;
  top: 0;
  left: 50%;
  max-width: 30vw;
  transform: translate(-50%, 0);
}

.progress-bar-container-position {
  position: relative;
  top: 0;
  left: 50%;
  max-width: 30vw;
  transform: translate(-50%, 0);
  margin-top: 4rem;

  @media screen and (max-width: 950px) {
    margin-top: 0;
  }
}

.simple-tour-planner-class {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 2rem;
  flex-direction: column;
}

.simple-tour-planner-how {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 2rem;
  flex-direction: column;
}

.simple-tour-planner-showcase {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 2rem;
  flex-direction: column;
}

.simple-tour-planner-position {
  height: 100%;
  display: flex;
  padding: 2rem;
  flex-direction: column;
  max-width: 40%;

  @media screen and (max-width: 1200px) {
    max-width: 50%;
  }

  @media screen and (max-width: 950px) {
    max-width: none;
    max-height: fit-content;
  }
}

.showcaseGif {
  position: absolute;
  z-index: 999;
  pointer-events: none;
  opacity: 0.3;
  margin-left: 25%;
  margin-bottom: 10%;

  @media screen and (max-width: 950px) {
    width: 150%;
  }
}

.class-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

.simple-tour-planner-title {
  font-family: ProximaNovaExtraBold;
  color: var(--darkblue);
  font-size: 3rem;
  margin-top: 3rem;
  line-height: normal;

  @media screen and (max-width: 1280px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 800px) {
    font-size: 2rem;
  }
}

.simple-tour-planner-form {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100%;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.how-item {
  min-width: 18rem;
  display: flex;

  @media screen and (max-width: 700px) {
    min-width: auto;
    flex-direction: column;
    justify-content: center;
  }

  @media screen and (max-width: 700px) {
    min-width: auto;
    flex-direction: column;
    justify-content: center;
  }

  @media screen and (max-width: 470px) {
    display: flex;
    min-width: auto;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    min-width: 100%;
  }
}

.form-item-title {
  font-family: ProximaNovaThin;
  color: var(--darkblue);
  font-size: 3rem;

  @media screen and (max-width: 800px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 600px) {
    font-size: 1.5rem;
  }
}

.custom-radio {
  min-width: 11rem;
}

.form-select {
  min-width: 15rem;
}

.form-select-gap {
  gap: 0rem;
}

.simpleShowcaseAnimations {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  height: fit-content;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: relative;

  .getStartedArrow {
    width: 2rem;
    filter: contrast(0) brightness(0);
    transition: all 150ms ease-out;
    z-index: 7;
  }

  @media screen and (max-width: 800px) {
    .getStartedArrow.left {
      position: absolute;
      left: 0;
      top: 25%;
      transform: translate(0, -50%) rotate(90deg);
    }

    .getStartedArrow.right {
      position: absolute;
      right: 0;
      top: 25%;
      transform: translate(0, -50%) rotate(-90deg);
    }
  }

  .getStartedArrow.disabled {
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
    opacity: 0.5;
  }
  .animCard {
    user-select: none;
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 5;
    transform-style: preserve-3d;
    animation-fill-mode: none;
  }
  .animCard.fadeOut {
    position: absolute;
    width: 0px;
  }
  .animCard.hidden {
    opacity: 0;
  }
  .animImage {
    width: 100%;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.75));
    -webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.75));
  }
  .animOverlay {
    user-select: none;
    position: absolute;
    color: black;
    width: 100%;
    text-wrap: nowrap;
    transform: translateZ(30px);
  }
  .animCardsHolder {
    --fixed-size: 16%;
    min-width: var(--fixed-size);
    max-width: var(--fixed-size);
    margin-left: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    transform-style: preserve-3d;
    .animCard {
      transform: rotateX(calc(var(--posY) * -25deg))
        rotateY(calc(var(--posX) * 25deg));
    }
  }

  @media screen and (max-width: 1200px) {
    .animCardsHolder {
      --fixed-size: 20%;
    }
  }
  @media screen and (max-width: 900px) {
    .animCardsHolder {
      --fixed-size: 25%;
    }
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }

  @media screen and (max-width: 700px) {
    .animCardsHolder {
      --fixed-size: 35%;
      margin-bottom: 1rem;
    }
  }

  @media screen and (max-width: 550px) {
    .animCardsHolder {
      --fixed-size: 45%;
      margin-bottom: 1rem;
    }
  }

  @media screen and (max-width: 450px) {
    .animCardsHolder {
      --fixed-size: 50%;
      margin-bottom: 1rem;
    }
  }

  @media screen and (max-width: 350px) {
    .animCardsHolder {
      --fixed-size: 55%;
      margin-bottom: 1rem;
    }
  }
}

.showcase-text {
  text-align: left;
  z-index: 8;
  width: 25vw;
  margin-left: 2rem;
  margin-right: 2rem;
  color: black;
  opacity: 1;
  transition: all ease-out 250ms;
  &.hide {
    opacity: 0;
  }
  h1 {
    font-family: ProximaNovaBold;
    font-size: 3rem;
    margin-bottom: 0.5rem;
  }
  p {
    font-family: ProximaNovaLight;
    font-size: 1.5rem;
    line-height: 1.5vw;
    text-align: justify;
    margin-bottom: var(line-height);
  }
  h2 {
    font-family: ProximaNovaBold;
    font-size: 3vw;
    margin-bottom: 0;
  }
  h3 {
    font-family: ProximaNovaThin;
    font-size: 2vw;
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
    h1 {
      font-family: ProximaNovaBold;
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    p {
      font-family: ProximaNovaLight;
      font-size: 1.2rem;
      line-height: normal;
    }
    h2 {
      font-family: ProximaNovaBold;
      font-size: 2rem;
    }
    h3 {
      font-family: ProximaNovaThin;
      font-size: 1.5rem;
    }
  }

  @media screen and (max-width: 800px) {
    text-align: center;
    margin-top: 1rem;
  }
}

.simple-tour-planner-split {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 950px) {
    flex-direction: column-reverse;

    .simple-tour-planner-map {
      min-height: 400px;
    }
  }
}

.simple-tour-planner-map {
  flex-grow: 1;
  border-left: 2px solid var(--ourorange);
}

.simple-tour-planner-map #pac-input {
  background-color: #fff;
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0 1px 4px -1px #0000004d;
  font-family: ProximaNovaLight;
  font-size: 15px;
  font-weight: 300;
  height: 40px;
  margin-left: 2rem;
  margin-top: 2rem;
  padding: 0 11px 0 13px;
  position: absolute;
  text-overflow: ellipsis;
  width: 180px;
  z-index: 2;
}

.drag {
  &.simple:not(.hide) {
    --size: 80px;
    color: white;
    font-weight: normal;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-family: ProximaNovaThin;
    font-size: 1.5rem;
    padding-top: 1rem;

    @media screen and (max-width: 500px) {
      --size: 60px;
      font-size: 1rem;
      padding-top: 0.75rem;
    }
  }
}

.simple-tour-planner-position {
  h5 {
    font-family: "DeLittleChromaticBold";
  }
  .hintP {
    font-family: ProximaNovaLight;
    white-space: pre-wrap;
    font-size: 1.2vw;
    line-height: 1.4vw;
  }
  p {
    font-family: ProximaNovaThin;
    white-space: pre-wrap;
    font-size: large;
  }

  .container {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .enumeration {
    width: 5.5vw;
    vertical-align: middle;
    display: flex;
    align-items: center;
    font-size: 1vw;
    font-weight: 600;
  }

  .alreadyPlaced {
    cursor: not-allowed;
    //opacity: 0.7;
  }

  .waypoint-name {
    color: var(--darkblue);
    font-family: ProximaNovaBold;
    font-size: 2rem;
    text-align: center;
    word-break: break-word;
  }
  .attribute-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .attribute {
    border: 1px var(--darkblue) solid;
    color: var(--darkblue);
    border-radius: 5px;
    padding: 3px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 4px;
    margin-right: 4px;
    margin-top: 2px;
    margin-bottom: 2px;
    text-align: center;
    box-sizing: border-box;
    font-size: 0.8em;
  }

  .marker-container {
    width: 10rem;
    min-height: 60px;

    @media screen and (max-width: 450px) {
      width: 7rem;
    }
  }

  .marker-area {
    width: 75%;
    height: auto;
    /*because border style is very specific*/
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='black' stroke-width='2' stroke-dasharray='5' stroke-dashoffset='6' stroke-linecap='round'/%3e%3c/svg%3e");
    border-radius: 5px;
    position: relative;
    aspect-ratio: 1;
    display: flex;
    flex-direction: column-reverse;
    cursor: pointer;

    i {
      display: flex;
      width: 100%;
      height: 100%;
      font-size: 3rem;
      rotate: -45deg;
      justify-content: center;
      align-items: center;
      color: white;
      caret-color: transparent;
    }
  }

  .waypointWrapper {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    margin-bottom: 1rem;
    @media screen and (max-width: 950px) {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
  }

  .waypoint-description {
    min-height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;

    @media screen and (max-width: 950px) {
      display: none;
    }
  }

  .resetButton {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .resetButton span {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
    font-family: "DeLittleChromaticBold";
  }

  .hide {
    display: none;
  }

  .pac-card {
    background-color: #fff;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
    margin: 10px;
    padding: 0 0.5em;
    font: 400 18px Roboto, Arial, sans-serif;
    overflow: hidden;
    font-family: Roboto;
    padding: 0;
  }

  #pac-container {
    padding-bottom: 12px;
    margin-right: 12px;
  }

  .pac-controls {
    display: inline-block;
    padding: 5px 11px;
  }

  .pac-controls label {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 300;
  }

  #pac-input {
    background-color: #fff;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
    margin-left: 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    height: 40px;
    width: 180px;
    position: absolute;
    z-index: 2;
    margin-top: 10px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    border-radius: 2px;
  }

  #pac-input:focus {
    border-color: #4d90fe;
  }

  .currentDistance {
    //font-weight: bold;
    font-family: ProximaNovaBold;
  }

  .recommendedDistance {
    margin-top: 0.5rem;
    white-space: pre-wrap;
    font-size: 1.3rem;
  }

  .waypointWrapper {
    .row {
      flex-wrap: nowrap;
    }
    .positionwaypoint {
      padding-right: 0;
      padding-left: 8px;
    }
  }

  .instructions {
    font-family: ProximaNovaLight;
    font-size: 1.5rem;
  }

  .circle {
    width: 30px;
    height: 30px;
    background-color: rgb(47 56 95 / 75%);
    border-radius: 50%;
    margin-right: 10px;
  }

  .circleDescription {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: pre-wrap;
    font-size: 1.3rem;
    justify-content: center;
  }

  @media screen and (min-width: 2000px) {
    .circleDescription {
      font-size: 1vw;
    }
    .currentDistance {
      font-size: 1vw;
    }
    p {
      font-size: 1vw;
    }
  }

  .position-class-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;

    @media screen and (max-width: 950px) {
      align-items: center;
      max-height: fit-content;
    }
  }

  .instructions-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: ProximaNovaThin;
  }

  .routeInfo {
    text-align: left;
    width: fit-content;
    margin: auto;
    margin-bottom: 3rem;
    font-family: ProximaNovaBold;
    .circleDescription {
      justify-content: left;
    }
  }

  .recommendedDistanceColor {
    color: #28a745 !important;
  }

  .notRecommendedDistanceColor {
    color: #ffc107 !important;
  }

  .simple-tour-planner-title {
    font-family: ProximaNovaExtraBold;
    color: var(--darkblue);
    font-size: 3rem;
    margin-top: 3rem;

    @media screen and (max-width: 1200px) {
      font-size: 2rem;
      margin-top: 1rem;
    }

    @media screen and (max-width: 450px) {
      font-size: 1.5rem;
      margin-top: 1rem;
    }
  }
}

.simple-more-adventures {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.guaranteeBadge {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 2rem;
  left: -18rem;
  background-color: white;
  width: 50rem;
  height: 5rem;
  z-index: 998;
  transform: rotateZ(25deg);
  color: var(--darkblue);

  // Transition
  transition: transform 250ms ease-out;
  &.hide {
    transform: rotateZ(25deg) translateY(200%);
  }

  p {
    margin-bottom: 0;
    font-family: ProximaNovaBold;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1.25rem;
  }

  // Garantierte Zufriedenheit
  > p {
    font-size: 0.8rem;
    letter-spacing: 0.1rem;
    line-height: 0.5rem;
  }

  > div {
    color: var(--ourorange);
    display: flex;
    flex-direction: row;

    // 100%
    > p:first-child {
      font-size: 3rem;
      line-height: 3rem;
      margin-right: 4px;
    }

    > div {
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      // Geld zurueck
      > p:first-child {
        font-size: 1rem;
        font-family: ProximaNovaExtraBold;
      }

      // Garantie
      > p:last-child {
        font-size: 1.5rem;
      }
    }
  }
}

.simple-tour-planner-position-loading-overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.85);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 997;

  @keyframes spin {
    0% { rotate: 0deg; }
    100% { rotate: 360deg; }
  }

  svg {
    height: 20%;
    margin-bottom: 48px;
    animation: spin 3s infinite forwards linear;
  }
  p {
    color: var(--ourorange);
    font-family: ProximaNovaLight;
    font-size: 1.75rem;
  }
}

.simple-tour-planner-material {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 2rem;
  flex-direction: column;

  --card-width: 800px;

  .container {
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    height: 100%;
  }

  .navigation-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
    z-index: 10;
    pointer-events: none;
    width: calc(var(--card-width) + 150px);

    @media screen and (max-width: 1200px) {
      width: 100%;
    }
  }

  .button {
    width: 40px;
    height: 40px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    pointer-events: auto;
    border: none;
    font-size: 2rem;
    
    transition: opacity 250ms ease-in-out;
    opacity: 1;
    &:disabled {
      opacity: 0;
      cursor: auto;
    }
  }

  .carousel-wrapper {
    overflow: unset;
    width: 100%;
    height: 100%;
    position: relative;
    transform: translate(0, -50%);
    top: 50%;

    @media screen and (max-width: 950px) {
      min-height: 500px;
    }

    @media screen and (max-width: 750px) {
      min-height: 600px;
    }

    @media screen and (max-width: 500px) {
      min-height: 600px;
    }
  }

  .carousel-inner {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 0%,
      /* Linke Seite transparent */ rgba(0, 0, 0, 1) 20%,
      /* Sichtbar */ rgba(0, 0, 0, 1) 80%,
      /* Sichtbar */ rgba(0, 0, 0, 0) 100%
    ); /* Rechte Seite transparent */
    mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 1) 80%,
      rgba(0, 0, 0, 0) 100%
    );
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    overflow: unset;

    @media screen and (max-width: 1024px) {
      mask-image: none;
    }
  }

  .material-card {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px;
    background-color: rgba($color: #FFF, $alpha: 0.6);
    border-radius: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    gap: 1rem;
    width: var(--card-width);
    min-height: 20rem;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 1600px) {
      --card-width: 700px;
      min-height: 17rem;
    }

    @media screen and (max-width: 1280px) and (min-width: 1025px),
    (max-width: 1280px) and (max-height: 700px) {
      --card-width: 450px;
      padding-left: 2rem;
      padding-right: 2rem;
      min-height: 0;
      .material-card-image-digital,
      .material-card-image-physical {
        min-height: auto;
      }
    }

    @media screen and (max-width: 950px) {
      --card-width: 75vw;
      .material-card-image-digital,
      .material-card-image-physical {
        min-height: 200px;
      }
    }

    @media screen and (max-width: 750px) {
    }
  }

  .material-card > .row {
    margin: 0 !important;
  }

  .material-card-image-digital {
    background-image: url("../img/Bild_Selbst_vorbereiten.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-width: 50%;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    min-height: 200px;
  }

  .material-card-image-physical {
    background-image: url("../img/Bild_Zuschicken_lassen.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-width: 50%;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    min-height: 200px;
  }

  .card-col {
    flex: 1 0;
    @media screen and (max-width: 950px) {
      flex: 0 0 auto;
      width: 100%;
    }
  }

  .material-card-title {
    font-family: ProximaNovaExtraBold;
    font-size: 2rem;
    color: black;

    @media screen and (max-width: 1600px) {
      font-size: 1.5rem;
    }

    @media screen and (max-width: 1280px) and (min-width: 1025px) {
      font-size: 1rem;
    }

    @media screen and (max-width: 750px) {
      text-align: center;
    }

    @media screen and (max-width: 550px) {
      font-size: 1.5rem;
    }
    
  }

  .material-card-description {
    margin-top: 0;

    @media screen and (max-width: 950px) {
      margin-top: 1rem;
    }

    @media screen and (max-width: 750px) {
      text-align: center;
    }
  }

  .material-card-description-text {
    font-family: ProximaNovaBold;
    font-size: 1.5rem;

    @media screen and (max-width: 1600px) {
      font-size: 1.25rem;
    }

    @media screen and (max-width: 1280px) and (min-width: 1025px) {
      font-size: 0.8rem;
    }

    @media screen and (max-width: 950px) {
      font-size: initial;
    }

  }

  .material-card-description-item-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1rem;

    @media screen and (max-width: 1280px) and (min-width: 1025px) {
      margin-top: 0;
    }

    @media screen and (max-width: 750px) {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 1rem;
    }
  }

  .material-card-description-item {
    display: flex;
    flex-direction: row;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-family: ProximaNovaThin;
    font-size: 1.4rem;

    .item-image {
      margin-right: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      height: 2rem;
    }

    p {
      margin-bottom: 0;
      line-height: 1.4rem;
    }

    p:last-child {
      font-size: 1rem;
      line-height: 1rem;
      font-family: ProximaNovaLight;
    }

    @media screen and (max-width: 1600px) {
      font-size: 1.25rem;
      line-height: 1.25rem;
    }

    @media screen and (max-width: 1280px) and (min-width: 1025px) {
      font-size: 0.75rem;
      line-height: 0.75rem;

      p:last-child {
        font-size: 0.75rem;
        line-height: 0.75rem;
      }
    }

    @media screen and (max-width: 950px) {
      font-size: initial;
      line-height: initial;
      p:last-child {
        font-size: initial;
        line-height: initial;
      }
    }
  }
}

.simple-tour-planner-summary {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 2rem;
  flex-direction: column;

  .text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .summary-title {
    font-family: ProximaNovaBold;
    color: var(--darkblue);
    font-size: 1.6rem;
  }

  .summary-value {
    font-family: ProximaNovaLight;
    font-size: 1.6rem;
  }

  .map-col {
    margin: 0;

    @media screen and (max-width: 850px) {
      margin-bottom: 1rem;
    }
  }

  @media screen and (max-width: 550px) {
    .summary-button {
      font-size: 1.5rem;
    }
  }

  @media screen and (max-width: 500px) {
    .summary-button {
      font-size: 1rem;
    }
  }
}

.simple-tour-planner-book {
  flex-grow: 0.7;
  height: 100%;
  display: flex;
  padding: 2rem;
  flex-direction: column;

  .simple-tour-planner-title {
    font-family: ProximaNovaExtraBold;
    color: var(--darkblue);
    font-size: 3rem;
    margin-top: 0rem;
  }

  .simple-tour-planner-form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    max-height: calc(100% - 72px);
    font-size: 1.1rem;
    font-family: ProximaNovaLight;

    @media screen and (max-width: 950px) {
      max-height: fit-content;
    }

    @media screen and (max-width: 1600px) {
      font-size: 1rem;
      .form-control {
        font-size: 1rem;
      }
    }
  }

  .form-check-input[type="radio"] + .form-check-label {
    margin-left: 1rem;
    margin-right: 3rem;
    margin-top: 0.4rem;
    font-size: inherit;
  }

  .booking-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;
    overflow: hidden;
  }

  .bookingcontainer .wrapper {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }

  .inline-checkbox-container {
    display: flex;
    flex-direction: row;
  }

  h5 {
    font-family: "ProximaNovaExtraBold";
    color: var(--darkblue);
  }

  .contact-form {
    //margin-top: 1rem;
  }

  .bookingcontainer .shipping-adress-form {
    margin-top: 0px;
  }
  .bookingcontainer .billing-adress-form {
    margin-top: 0px;
  }

  input:not([type="radio"]):not([type="checkbox"]),
  select,
  textarea {
    border: 1px solid var(--ourorange) !important;
    &:focus {
      box-shadow: none !important;
      border-color: var(--ourorange) !important;
    }
  }

  // Override Bootstrap's form validation borders
  .form-control.is-valid,
  .form-control.is-invalid {
    border-color: var(--ourorange) !important;
    &:focus {
      box-shadow: none !important;
      border-color: var(--ourorange) !important;
    }
  }
}

.simple-tour-planner-book-summary {
  flex-grow: 0.3;
  border-left: 2px solid var(--ourorange);
  display: flex;
  flex-direction: column;
  width: fit-content;
  min-width: 350px;
  box-sizing: border-box;
  font-family: ProximaNovaLight;
  font-size: 1.2rem;

  @media screen and (max-width: 1462px) {
    display: none;
  }

  h5 {
    font-family: "ProximaNovaExtraBold";
    color: var(--darkblue);
  }

  .detail-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1rem;
    padding-top: 4rem;

    .summary-title {
      font-family: "ProximaNovaExtraBold";
      color: var(--darkblue);
    }
  }

  .price-container {
    border-top: 2px solid var(--ourorange);
    padding: 1rem;
    display: flex;
    justify-content: space-between;
  }

  .summaryMapWrapper {
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .total-price-title {
    font-family: "ProximaNovaExtraBold";
    color: var(--darkblue);
  }

  .total-price {
    font-family: "ProximaNovaExtraBold";
    color: var(--darkblue);
  }
}

.buttons-container {
  transition: all 0.3s ease-in-out !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
}
