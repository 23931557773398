.call-to-action-hoverbox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 5vw;
    height: 10vw;
    background-color: transparent;
    position: fixed;
    top: 1vw;
    right: 0px;
    z-index: 2;
    transition: transform 250ms ease-in-out;
    &.hidden {
        .call-to-action {
            transform: translateX(150%) !important;
        }
    }
    &.small .call-to-action {
        transform: translateX(102.5%);
        max-height: 8.5vw;
        border-radius: 1vw;
        .icon {
            opacity: 0;
        }
        .short {
            opacity: 1;
            transform: rotate(-90deg);
        }
    }
    &.small:hover {
        width: 15vw;
        //background-color: green;
        .call-to-action {
            border-radius: 4vw 0px 0px 4vw;
            transform: translateX(20%);
            max-height: 4.25vw;
            .short {
                opacity: 0;
                transform: rotate(-90deg);
            }
            .icon {
                opacity: 1;
            }
        }
    }
}

.call-to-action {
    --transition-duration: 250ms;
    position: fixed;
    right: 3vw;
    display: flex;
    align-items: center;
    flex-direction: row;
    overflow: hidden;
    font-family: "ProximaNovaBold";
    font-size: 1.25vw;
    background-color: #FF6641;
    border-radius: 4vw;
    padding: 1.3vw 1.2vw 1.3vw 1.2vw;
    white-space: pre-line;
    line-height: 1.1vw;
    -webkit-box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.75); 
    box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.75);
    @media screen and (max-width: 978px) {
        display: none;
    }
    transition: all var(--transition-duration) ease-in-out;
    cursor: pointer;
    max-height: 4.25vw;
    height: 100%;
}

.call-to-action .icon {
    opacity: 1;
    background-image: url("../img/icon/GPS.svg");
    background-repeat: no-repeat;
    opacity: 1;
    width: 3vw;
    height: 3vw;
    transition: all calc(var(--transition-duration) - 100ms) ease-in-out;
}

.call-to-action .short {
    position: absolute;
    text-align: center;
    left: -16%;
    top: 3.75vw;
    transform: rotate(-90deg) scaleX(2);
    opacity: 0;
    transition: all calc(var(--transition-duration) - 100ms) ease-in-out;
    font-size: 110%;
}

.navbar {
    position: fixed;
    padding-top: 7rem;
    height: 100dvh;
    padding-left: 1%;
    padding-right: 1%;  
    top: 0px;
    left: 0px;
    opacity: 0;
    background-color: rgba(0,0,0,0.75);
    color: #FF6641;
    transition: 300ms ease-in-out;
    transform: translateX(-100%);
    font-family: "ProximaNovaThin";
    font-size: calc(1.2rem + 0.5vw);
    text-align: left;
    z-index: 88888;
    scrollbar-gutter: stable;
    overflow-y: auto;
    overflow-x: hidden;
    p {
        margin-bottom: 0;
        text-align: center;
    }
}

.nav-flex {
    flex-direction: column !important;
    height: 100%;
}

.navbar.show {
    opacity: 1;
    transform: translateX(0%);
}

.navbar .link {
    color: white;
    text-transform: uppercase;
    transition: 300ms ease-in-out;
}

.navbar .link.active {
    color: white; // Avoid text turning black through default params when clicking on an option
}

.navbar .link:hover {
    color: #FF6641;
}

.navbar-expand .navbar-nav .nav-link {
    @media only screen and (max-width: 800px) {
      font-size: 1.5rem;
    }
  }

.navbar .line {
    width: 100%;
    height: 1px;
    background-color: #FF6641;
    margin-bottom: 2rem;
}

.navbar .footer {
    display: inline-block;
    align-self: center;
    margin-top: 10px;
    margin-bottom: 2vw;
}

.navbar .phonesymbol {
    display: inline-block;
    background-image: url(../img/icon/Telefon.svg);
    background-repeat: no-repeat;
    --min-size: 1.9rem;
    --size: 0vw;
    min-width: var(--min-size);
    min-height: var(--min-size);
    width: var(--size);
    height: var(--size);
    margin-right: 1.4rem;
    transition: transform 200ms ease-in-out;
    cursor: pointer;
    .onhover {
        position: absolute;
        width: fit-content;
        font-size: 1.5rem;
        top: -3pc;
        left: 0;
        background-color: #E5E7EB;
        transform: translateX(-25%);
        transition: all 200ms ease-in-out;
        margin-bottom: 0;
        opacity: 0;
        padding-left: 0.25pc;
        padding-right: 0.4pc;
        color: black;
        border-radius: 2pc;
    }
    &:hover {
        .onhover {
            opacity: 1;
        }
    }
}

.navbar .phonesymbol:hover {
    transform: scale(1.3, 1.3);
}

.navbar .emailsymbol {
    display: inline-block;
    background-image: url(../img/icon/Mail.svg);
    background-repeat: no-repeat;
    --min-size: 2.3rem;
    --size: 0vw;
    --height: 0.4rem;
    min-width: var(--min-size);
    min-height: calc(var(--min-size) - var(--height));
    width: var(--size);
    height: calc(var(--size) - var(--height));
    transition: transform 200ms ease-in-out;
    cursor: pointer;
}

.navbar .emailsymbol:hover {
    transform: scale(1.3, 1.3);
}

@media screen and (max-width: 800px) {
    .navbar {
        scrollbar-gutter: stable;
        overflow-y: auto;
        overflow-x: hidden;
        font-size: 8vw;
        width: 100dvw;
        padding-top: 4rem;
    }   

    .navbar .footer {
        margin-bottom: 4vh;
    }
    
    .navbar .phonesymbol {
        width: 12vw;
        height: 12vw;
        margin-right: 2rem;
    }
    
    .navbar .phonesymbol:hover {
        transform: none;
    }
    
    .navbar .emailsymbol {
        width: 12vw;
        height: 10.7vw;
    }
    
    .navbar .emailsymbol:hover {
        transform: none;
    }
}
@media screen and (min-width: 3000px) {
    .navbar {
        font-size: 2.5vw;
    }   
    .navbar .footer {
        margin-bottom: 1vh;
    }
    .navbar .phonesymbol {
        width: 4vw;
        height: 4vw;
        margin-right: 8rem;
    }
    .navbar .emailsymbol {
        width: 4vw;
        height: 3.5vw;
    }
}