@import url("../../assets/scss/common.scss");

.dp-bg {
    position: fixed;
    top: 0;
    z-index: -1;
    overflow: initial;
}

.dp-headline {
    position: fixed;
    top: 0;
    right: 8vw;
    font-size: 6vw !important;
    margin-top: 8vw !important;
    z-index: 1;
    text-shadow: black -2px 2px 8px;
}

.dp-holder {
    width: 85%;
    margin: auto;
    color: white;
    h1 {
        text-align: center;
        font-family: DeLittleChromaticBold;
        font-size: 5rem;
        margin-top: 3pc;
        margin-bottom: 3pc;
    }

    h2 {
        font-family: ProximaNovaBold;
        font-size: 3rem;
        margin-top: 3.75rem;
        margin-bottom: 2pc;
    }

    h3 {
        font-family: ProximaNovaBold;
        font-size: 2.5rem;
        margin-top: 3rem;
        margin-bottom: 2pc;
    }

    h4 {
        font-family: ProximaNovaBold;
        font-size: 1.75rem;
        margin-top: 2pc;
        margin-bottom: 1pc;
    }

    p {
        font-family: ProximaNovaLight;
        font-size: 1.8rem;
        line-height: 2rem;
        font-weight: 100;
    }

    b.bigger {
        font-size: 2.25rem;
        line-height: 2.4rem;
        font-weight: 800;
    }

    ul li {
        font-family: ProximaNovaLight;
        font-size: 1.5rem;
        line-height: 1.75rem;
        margin-bottom: 8px;
    }

    a {
        word-break: break-all;
    }

    @media screen and (max-width: 768px) {
        padding-top: 5pc;
        width: 90%;

        h1 {
            text-align: center;
            font-family: DeLittleChromaticBold;
            font-size: 8vw;
            margin-top: 3pc;
            margin-bottom: 3pc;
        }
    
        h2 {
            font-family: ProximaNovaBold;
            font-size: 2.5rem;
            margin-top: 3.75rem;
            margin-bottom: 2pc;
        }
    
        h3 {
            font-family: ProximaNovaBold;
            font-size: 2rem;
            margin-top: 2rem;
            margin-bottom: 2pc;
        }
    
        h4 {
            font-family: ProximaNovaBold;
            font-size: 1.75rem;
            margin-top: 2pc;
            margin-bottom: 1pc;
        }
    
        p {
            font-family: ProximaNovaLight;
            font-size: 1.35rem;
            line-height: 1.5rem;
            font-weight: 100;
        }

        ul li {
            font-size: 4.7vw;
            line-height: 4.9vw;
        }

        b.bigger {
            font-size: 6vw;
            line-height: 6.2vw;
        }
    }
}

body {
    background-color: #000000 !important;   
}