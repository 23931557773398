.pagefooter {
    color: #FFFFFF;
    background-image: url('../img/Background_Footline.png');
    background-position: 0px -1pc;
    background-size: cover;
    background-repeat: round;
    border-top: 2px solid rgba($color: #FFFFFF, $alpha: 0.3);
    text-align: center;
    padding: 0;
    padding-bottom: 1vw;
    font-family: ProximaNovaLight;
    @media screen and (max-width: 768px) {
        background-position: 0px 8pc;
    }
}

.pagefooter .line {
    margin-top: 1pc;
    font-size: 1vw;
    @media screen and (max-width: 1200px) {
        font-size: 1.5vw;
    }
    @media screen and (max-width: 768px) {
        font-size: 3vw;
    }
}

.pagefooter .line a {
    margin-left: 2rem;
    color: white;
    text-decoration: none;
    transition: color 0.1s ease-in-out;
}
@media only screen and (max-width: 768px) {
    .pagefooter .line a {
        margin-left: 5%;
    }
}

.pagefooter .line span {
    margin-left: 2rem;
}
@media only screen and (max-width: 768px) {
    .pagefooter .line span {
        margin-left: 5%;
    }
}

.pagefooter .line a:hover {
    color: #ff6641;
    text-decoration: underline;
    cursor: pointer;
}