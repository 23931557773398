.explore-content {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;

}

.explore-shorts-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 10px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 7vh;

  @media screen and (max-width: 1400px) {
    width: 70%;
  }

  @media screen and (max-width: 700px) {
    width: 85%;
  }

  @media screen and (max-width: 570px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
    margin-top: 4vh;
  }
}

.explore-subject {
  text-align: center;
  /*   width: 20%; */
  transform: translateX(-10%);
  transition: 0.8s ease-out;
  .head {
    font-family: "ProximaNovaBold";
    font-size: 1.5rem;
    color: white;
  }
  .text {
    text-align: center;
    font-family: "ProximaNovaLight";
    font-size: 1.4rem;
    line-height: 1.4rem;
    color: white;
  }
  .icon {
    height: 3rem;
    margin-bottom: 0.5rem;
    align-self: center;
  }

  @media screen and (max-width: 920px) {
    .head {
      font-size: 1.3rem;
    }
    .text {
      font-size: 1.2rem;
      line-height: normal;
    }
    .icon {
      height: 2.5rem;
    }
  }

  @media screen and (max-width: 500px) {
    .head {
      font-size: 1.1rem;
    }
    .text {
      font-size: 1rem;
      line-height: normal;
    }
    .icon {
      height: 1.8rem;
    }
  }
}

.explore-fade-in .explore-subject {
  // Animation on scroll
  opacity: 1;
  transform: translateX(0%);
}
.explore-fade-instant .explore-subject {
  opacity: 1;
  transform: translateX(0%);
  transition-delay: 0ms;
}

.explore-headline {
  font-family: "ProximaNovaExtraBold";
  font-size: 4rem;
  transition: margin-left ease-in-out 100ms;
  color: white;
  text-align: center;
  margin-bottom: 4rem;
  position: relative;
  z-index: 2;

  @media screen and (max-width: 1480px) {
    font-size: 3rem;
  }

  @media screen and (max-width: 550px) {
    font-size: 2rem;
  }
}

.explore-text {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
  font-family: "ProximaNovaLight";
  font-size: 1.4rem;
  line-height: normal;
  padding-top: 2rem;
  color: white;

  @media screen and (max-width: 1400px) {
    width: 70%;
  }

  @media screen and (max-width: 920px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 700px) {
    width: 85%;
  }

  @media screen and (max-width: 570px) {
    padding-top: 1rem;
  }

}

.shortterm-position {
  top: 20%;
  left: 6%;
}
.everywhere-position {
  bottom: 20%;
  left: 6%;
}
.individuel-position {
  top: 20%;
  right: 6%;
}
.custom-position {
  bottom: 20%;
  right: 6%;
}
@media screen and (max-width: 1200px) {
  .shortterm-position {
    top: 20%;
    left: 5%;
  }
  .everywhere-position {
    bottom: 20%;
    left: 5%;
  }
  .individuel-position {
    top: 20%;
    right: 5%;
  }
  .custom-position {
    bottom: 20%;
    right: 5%;
  }
}

.resp {
  .explore-text {
    margin: auto;
    width: 85%;
    font-size: var(--normal-p);
    line-height: var(--normal-p);
  }
  .explore-subject {
    opacity: 1;
    transform: none;
    transition: none;
    margin-top: 2pc;
    position: inherit;
    width: 90%;
    .head {
      font-family: "DeLittleChromaticBold";
      font-size: 6rem;
      color: white;
    }
    .text {
      font-family: "ProximaNovaLight";
      font-size: 5rem;
      line-height: 5rem;
      color: white;
    }
    .icon {
      height: 25rem;
      margin-bottom: 0.5rem;
      align-self: center;
    }
  }
}
