@import url("../../assets/scss/common.scss");

body {
    background-color: #000000 !important;   
}

.imprint-bg {
    position: fixed;
    top: 0;
    z-index: -1;
    overflow: initial;
}

.imprint-holder {
    width: 100%;
    color: white;
    font-family: ProximaNovaLight;
    font-size: 1vw;
    line-height: 1.1vw;

    @media screen and (max-width: 768px) {
        margin-top: 5pc;
    }

    .text {
        width: 65%;
        margin: auto;
        margin-top: 5pc;
        p {
            margin-bottom: 3pc;
        }
        h2 {
            margin-top: 6pc;
            margin-bottom: 2vw;
            font-size: 2.5vw;
            font-family: ProximaNovaBold;
            word-break: break-word;
        }
        @media screen and (max-width: 1200px) {
            p {
                margin-bottom: 1pc;
                font-size: 2vw;
                line-height: 2vw;
            }
            h2 {
                margin-top: 3pc;
                margin-bottom: 1pc;
                font-size: 3vw;
                font-family: ProximaNovaBold;
            }
        }
        @media screen and (max-width: 768px) {
            width: 75%;
            p {
                margin-bottom: 1pc;
                font-size: 4.5vw;
                line-height: 5vw;
            }
            h2 {
                margin-top: 3pc;
                margin-bottom: 1pc;
                font-size: 6vw;
                font-family: ProximaNovaBold;
            }
        }
    }

    > h2 {
        text-align: center;
        font-family: DeLittleChromaticBold;
        font-size: 4vw;
        margin-top: 3pc;
        margin-bottom: 10pc;
        @media screen and (max-width: 768px) {
            top: 0;
            right: 4vw;
            margin-top: 4vw;
            position: fixed;
            font-size: 6vw;
            text-shadow: black -2px 2px 12px;
        }
    }

    .three-pointer {
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 70%;
        div {
            width: 40%;
            p {
                margin-bottom: 0;
                line-height: 1.1vw;
            }
            > p:nth-child(1) {
                font-family: ProximaNovaThin;
                font-size: 1.5vw;
                line-height: 2.5rem;
                margin-bottom: 0.5vw;
            }
        }
        @media screen and (max-width: 1200px) {
            width: 90%;
            div {
                margin-bottom: 3pc;
                width: 90%;
                p {
                    margin-bottom: 0;
                    font-size: 1.25rem;
                    line-height: 1.25rem;
                }
                > p:nth-child(1) {
                    font-family: ProximaNovaThin;
                    font-size: 1.5rem;
                    line-height: 1.5rem;
                    margin-bottom: 0.5vw;
                }
            }
        }
        @media screen and (max-width: 768px) {
            width: 75%;
            flex-direction: column;
            div {
                margin-bottom: 3pc;
                width: 90%;
                p {
                    margin-bottom: 0;
                    font-size: 1.25rem;
                    line-height: 1.25rem;
                }
                > p:nth-child(1) {
                    font-family: ProximaNovaThin;
                    font-size: 1.5rem;
                    line-height: 1.5rem;
                    margin-bottom: 0.5vw;
                }
            }
        }
    }
}