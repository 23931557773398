.showcaseAnimations {
    display: flex;
    flex-direction: row;
    margin-top: 6pc;
    margin-bottom: 1pc;
    width: 100%;
    height: fit-content;
    text-align: center;
    align-items: center;
    justify-content: center;
    .getStartedArrow {
        width: 3%;
        filter: contrast(0) brightness(2);
        transition: all 150ms ease-out;
        z-index: 7;
    }
    .getStartedArrow.disabled {
        filter: grayscale(1);
        -webkit-filter: grayscale(1);
        opacity: 0.5;
      }
    .animCard {
        user-select: none;
        display: flex;
        flex-direction: column;
        width: 100%;
        z-index: 5;
        transform-style: preserve-3d;
        animation-fill-mode: none;
    }
    .animCard.fadeOut {
        position: absolute;
        width: 0px;
    }
    .animCard.hidden {
        opacity: 0;
    }
    .animImage {
        width: 100%;
        filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.75));
        -webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.75));
    }
    .animOverlay {
        user-select: none;
        position: absolute;
        color: black;
        width: 100%;
        text-wrap: nowrap;
        transform: translateZ(30px);
    }
    .animCardsHolder {
        --fixed-size: 20%;
        min-width: var(--fixed-size);
        max-width: var(--fixed-size);
        margin-left: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        transform-style: preserve-3d;
        .animCard {
            transform: rotateX(calc(var(--posY) * -25deg)) rotateY(calc(var(--posX) * 25deg));
        }
    }
}

.showcase-text {
    text-align: left;
    z-index: 8;
    width: 25vw;
    margin-left: 6pc;
    margin-right: 2rem;
    color: white;
    opacity: 1;
    transition: all ease-out 250ms;
    &.hide {
        opacity: 0;
    }
    h1 {
        font-family: ProximaNovaBold;
        font-size: 3vw;
        margin-bottom: 2rem;
    }
    p {
        font-family: ProximaNovaLight;
        font-size: 1.5vw;
        line-height: 1.5vw;
        margin-bottom: var(line-height);
    }
    h2 {
        font-family: ProximaNovaBold;
        font-size: 3vw;
        margin-bottom: 0;
    }
    h3 {
        font-family: ProximaNovaThin;
        font-size: 2vw;
    }
    @media screen and (max-width: 1200px) {
        h1 {
            font-family: ProximaNovaBold;
            font-size: 4vw;
            margin-bottom: 1rem;
        }
        p {
            font-family: ProximaNovaLight;
            font-size: 2vw;
            line-height: 1.8vw;
        }
        h2 {
            font-family: ProximaNovaBold;
            font-size: 4vw;
        }
        h3 {
            font-family: ProximaNovaThin;
            font-size: 3vw;
        }
    }
    &.mobile {
        margin: auto;
        width: 80vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        height: 100%;
        color: white;
        h1 {
            font-family: ProximaNovaBold;
            text-align: center;
            font-size: 8vw;
            margin-top: 3rem;
            margin-bottom: 1rem;
            color: white;
        }
        p {
            font-family: ProximaNovaLight;
            font-size: var(--big-p);
            line-height: var(--big-p);
            @media screen and (min-width: 767px) and (max-width: 768px) and (max-height: 1024px) {
                font-size: var(--normal-p);
                line-height: var(--normal-p);
            }
        }
        h2 {
            text-align: center;
            font-family: ProximaNovaBold;
            font-size: 8vw;
            margin-bottom: 0;
        }
        h3 {
            text-align: center;
            font-family: ProximaNovaThin;
            font-size: 7vw;
        }
    }
}

.showcase_button {
    @extend .ourBg;
    height: 3.8vw;
    font-size: 1.9vw;
    font-family: ProximaNovaBold;
    color: black;
    background-color: #ff6641;
    border-color: #ff6641;
    text-transform: uppercase;
    border-radius: 1pc;
}